button.ant-btn.add-new-app{
    font-family: SFProDisplay-Semibold;
    color: var(--slate-grey);
    font-size: 14px;
    border-radius: 5px;
    background-color: var(--white);
}
button.ant-btn.add-new-app:hover{
    color: var(--dodger-blue);
    border: solid 1px var(--dodger-blue);
}
button.ant-btn.add-new-app:hover > div > i > svg > g > path{
    fill: var(--dodger-blue);
}

button.ant-btn.create-product-group{
    font-family: SFProDisplay-Medium;
    color: var(--dodger-blue);
    font-size: 12px;
    border-radius: 5px;
    background-color: var(--white);
}
button.ant-btn.create-product-group:hover{
    border: solid 1px var(--dodger-blue);
}
button.ant-btn.create-product-group:focus{
    border: solid 1px var(--dodger-blue);
}
button.ant-btn.create-product-group > div > i > svg > g > path{
    fill: var(--dodger-blue);
}

button.ant-btn.learn-more{
    font-family: SFProDisplay-Medium;
    color: var(--dodger-blue);
    font-size: 12px;
    border-radius: 5px;
    background: #e8f4ff;
}
button.ant-btn.learn-more:hover{
    border: solid 1px var(--dodger-blue);
}
button.ant-btn.back-app-details{
    font-family: SFProDisplay-Regular;
    color: var(--dark);
    font-size: 14px;
    border-radius: 5px;
}
button.ant-btn.back-app-details:hover, button.ant-btn.back-app-details:active, button.ant-btn.back-app-details:focus{
    border: solid 1px var(--dodger-blue);
    color: var(--dodger-blue);
}
button.ant-btn.back-app-details:hover > div > i > div > svg > g > path{
    fill: var(--dodger-blue);
}

button.ant-btn.add-group-item{
    font-family: SFProDisplay-Medium;
    color: var(--dark);
    font-size: 12px;
    border-radius: 5px;
}
button.ant-btn.add-group-item:hover{
    border: solid 1px var(--dodger-blue);
    color: var(--dodger-blue);
}
button.ant-btn.add-group-item:focus{
    border: solid 1px var(--dodger-blue);
}
button.ant-btn.add-group-item > div > i > svg > g > path{
    fill: var(--dark-grey-blue);
}
button.ant-btn.add-group-item:hover > div > i > svg > g > path{
    fill: var(--dodger-blue);
}

button.ant-btn.sent-test-event{
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: solid 2px var(--dark-sky-blue);
    background-color: var(--white);
    color: var(--dark-sky-blue);
    font-family: SFProDisplay-Semibold;
    font-size: 15px;
}
button.ant-btn.sent-test-event:hover, button.ant-btn.sent-test-event:focus, button.ant-btn.sent-test-event:active{
    background-color: var(--ice-blue);
}


button.ant-btn.save-now-webhooks{
    width: 138px;
    height: 40px;
    border-radius: 5px;
    border: solid 2px var(--dark-sky-blue);
    background-color: var(--dark-sky-blue);
    color: var(--white);
    font-family: SFProDisplay-Semibold;
    font-size: 15px;

    transition: all 0.3s;
    transition-property: all;
    transition-duration: 0.5s, 0s;
    transition-timing-function: ease, ease;
    transition-delay: 0s, 0s;
}
button.ant-btn.save-now-webhooks:hover, button.ant-btn.save-now-webhooks:focus, button.ant-btn.save-now-webhooks:active{
    background-color: var(--ice-blue);
    color: var(--dark-sky-blue);
}


.activate-product{
    font-family: SFProDisplay-Semibold;
    font-size: 13px;
    padding: 0!important;
    height: 20px;
    background-color: transparent;
    border: none;
    color: var(--dark-sky-blue);
    box-shadow: none;
    margin-left: 10px;
}
.activate-product:hover{
    color: var(--water-blue);
    background-color: transparent;
    border: none;
}
/*button.ant-btn.activate-product:focus{*/
/*    border: 0 !important;*/
/*    box-shadow: none !important;*/
/*    outline: none !important;*/
/*    animation-duration: 0s !important;*/
/*}*/
/*button.ant-btn.activate-product:after{*/
/*    border: 0 !important;*/
/*    box-shadow: none !important;*/
/*    outline: none !important;*/
/*    animation-duration: 0s !important;*/
/*}*/

button.ant-btn.filter-apply{
    font-family: SFProDisplay-Semibold;
    color: var(--white);
    font-size: 14px;
    background-color: var(--dark-sky-blue);
    border: none;
}
button.ant-btn.filter-apply:hover{
    background-color: var(--dodger-blue);
    border: none;
}

button.ant-btn.filter-cancel{
    font-size: 14px;
    font-family: SFProDisplay-Semibold;
    color: var(--battleship-grey-two);
    box-shadow: none !important;
    border: none;
}

button.ant-btn.filter-cancel:hover{
    color: var(--dodger-blue);
    border: none;
}

button.ant-btn.filter-cancel:after{
    border: 0 !important;
    box-shadow: none !important;
    outline: none !important;
    animation-duration: 0s !important;
}

button.ant-btn.add-payment-method{
    padding: 0;
    font-family: SFProDisplay-Semibold;
    color: var(--greyish-brown);
    font-size: 13px;
    border: none;
    box-shadow: none !important;
    outline: none !important;
    animation-duration: 0s !important;
}

button.ant-btn.add-payment-method:hover{
    color: var(--azure);
}
button.ant-btn.add-payment-method:after{
    border: 0 !important;
    box-shadow: none !important;
    outline: none !important;
    animation-duration: 0s !important;
}

button.remove-card{
    font-family: SFProDisplay-Regular;
    font-size: 14px;
    color: var(--slate-grey);
    background-color: var(--white);
    border: solid 1px var(--cool-grey);
}

button.remove-card:hover, button.remove-card:active, button.remove-card:focus{
    color: var(--white);
    background-color: var(--slate-grey);
    border: solid 1px var(--slate-grey);

    transition: all 0.3s;
    transition-property: all;
    transition-duration: 0.5s, 0s;
    transition-timing-function: ease, ease;
    transition-delay: 0s, 0s;
}

button.make-default-card{
    background-color: var(--cool-grey);
    font-family: SFProDisplay-Regular;
    font-size: 14px;
    color: var(--white);
    border: solid 1px var(--cool-grey);
}

button.make-default-card:hover, button.make-default-card:focus, button.make-default-card:active{
    background-color: var(--slate-grey);
    color: var(--white);
    border: solid 1px var(--slate-grey);
}

button.select-card{
    background-color: var(--white);
    font-family: SFProDisplay-Regular;
    font-size: 14px;
    color: var(--slate-grey);
    border: solid 1px var(--cool-grey);
}

button.select-card:hover, button.select-card:focus, button.select-card:active{
    background-color: var(--dark-sky-blue);
    color: var(--white);
    border: solid 1px var(--dark-sky-blue);
}

button.selected-card, button.selected-card:hover, button.selected-card:focus, button.selected-card:active{
    background-color: var(--dark-sky-blue);
    font-family: SFProDisplay-Regular;
    font-size: 14px;
    color: var(--white);
    border: solid 1px var(--dark-sky-blue);
}

button.purchase-subscription{
    font-family: SFProDisplay-Semibold;
    font-size: 15px;
    border-radius: 5px;
    color: var(--white);
    background-color: var(--dark-sky-blue);
}
button.purchase-subscription:hover{
    color: var(--white);
    background-color: var(--azure);
}

button.cancel-subscription{
    font-family: SFProDisplay-Semibold;
    font-size: 15px;
    border-radius: 5px;
    color: var(--dark-grey-blue);
    border: solid 1px var(--cloudy-blue);
    background-color: var(--white);
}
button.cancel-subscription:hover{
    /*color: var(--white);*/
    /*color: var(--salmon);*/
    border: solid 1px var(--water-blue);
}


button.ant-btn.deep-sky-blue-and-white{
    font-family: SFProDisplay-Semibold;
    color: var(--white);
    font-size: 14px;
    border-radius: 5px;
    background-color: var(--deep-sky-blue);
    border-color: var(--deep-sky-blue);
    box-shadow: 0 1px 4px 0 rgba(85, 92, 97, 0.34);
}

button.ant-btn.white-and-deep-sky-blue{
    font-family: SFProDisplay-Semibold;
    color: var(--deep-sky-blue);
    font-size: 14px;
    border-radius: 5px;
    background-color: var(--white);
    border-color: var(--white);
    box-shadow: 0 1px 4px 0 rgba(85, 92, 97, 0.34);
}

button.ant-btn.extra-feature-upgrade{
    font-family: SFProDisplay-Semibold;
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: var(--white);
    background-color: var(--dark-sky-blue);
}





.ant-layout-header{
    padding-left: 16px;
    padding-right: 20px;
}

.ant-btn.button-account{
    height: 80%;
    border: none;
    background-color: transparent;
    font-family: SFProDisplay-Semibold;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--white);
}

.top-bar .ant-dropdown-menu{
    border-radius: 0px;
    width: 218px;
}

.ant-badge-dot{
    height: 8px;
    width: 8px;
    background: var(--dodger-blue);
    border-radius: 100%;
    border: none;
}

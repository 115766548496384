
.ant-tag.white-dark-mint{
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 1px;
    height: 24px;
    font-size: 13px;
    font-family: SFProDisplay-Semibold;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    background: var(--white);
    border-color: var(--dark-mint);
    color: var(--dark-seafoam-green);
  }
  
  .ant-tag.white-cool-grey{
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 1px;
    height: 24px;
    font-size: 13px;
    font-family: SFProDisplay-Semibold;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    background: var(--white);
    border-color:  var(--cool-grey);
    color:  var(--cool-grey);
  }
  
.apps .ant-form-extra {
    font-family: SFProDisplay-Semibold;
    font-size: 12px;

    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--cool-grey);
}

.apps .ant-form-item-label > label{
    font-family: SFProDisplay-Regular;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.46;
    letter-spacing: normal;
    color: var(--greyish-brown);
}

.apps .ant-input{
    font-family: SFProDisplay-Regular;
    font-size: 15px;
    border-radius: 4px;
    border: solid 1px var(--cloudy-blue);
    background-color: var(--white);
    height: 42px;
}

.apps .ant-input::placeholder,.apps .ant-select-selection__placeholder{
    font-family: SFProDisplay-Regular;
    font-size: 15px;
    color: var(--cool-grey) !important;
}

div.ant-tabs-bar{
    width: 0px;
}
div.ant-tabs-nav-container{
    width: 0px;
}

div.ant-tabs-bar, div.ant-tabs-left-bar{
    border-right: none !important;
}

div.ant-tabs-content, div.ant-tabs-content-animated, div.ant-tabs-left-content{
    border-left: none !important;
}



.app-list .ant-row-flex .ant-row-flex-middle {
    flex-basis: 360px;
}

.ant-modal-content {
    width: 528px;
    border-radius: 5px;
    border: solid 1px #e5eef5;
}

.ant-modal-header {
    padding: 19px 0px 16px 22px;
}

.ant-modal-title span {
  font-family: SFProDisplay-Semibold;
  font-size: 18px;

  color: #262626;
}

.ant-modal-body {
    padding: 19px 22px 6px 22px;
}
.ant-modal-body .ant-form-item-label > label {
    font-family: SFProDisplay-Medium;
    font-size: 13px;

    color: #404040;
}

/* .ant-modal-body .ant-form-item-control-wrapper {
    width: 484px;
} */

.price-history tbody tr *{
    font-family: SFProDisplay-Medium !important;
    font-size: 15px;

    color: var(--cool-grey);
}

.app-list-container{
    padding: 32px 52px 0px 36px;
}
.app-list-item{
    height: 96px;
    border-radius: 8px;
    padding-left: 8px;
}

.app-list-item:hover{
    background: #e5eef5;
}

.ant-avatar-square{
    border-radius: 16px;
}

.ant-spin-dot-item{
    background-color: var(--dark-sky-blue);
}

.eina-semibold-dark-12{
    font-family: SFProDisplay-Semibold;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dark);
}
.ant-btn.stroke-cloudy-blue{
    height: 36px;
    border-radius: 5px;
    background-color: var(--white);
    border: solid 1px var(--cloudy-blue);

}
.ant-btn.stroke-cloudy-blue:hover, .ant-btn.stroke-cloudy-blue:focus, .ant-btn.stroke-cloudy-blue:active{
    color: var(--dark-sky-blue);
    border: solid 1px rgb(0, 127, 255);
}

.ant-btn.dark-grey-blue{
    height: 36px;
    border-radius: 5px;
    color: var(--dark-grey-blue);
    background-color: var(--white);
    border: solid 1px var(--dark-grey-blue);

}
.ant-btn.dark-grey-blue:hover, .ant-btn.dark-grey-blue:focus, .ant-btn.dark-grey-blue:active{
    color: var(--dark-sky-blue);
    border: solid 1px rgb(0, 127, 255);
}

button.ant-btn.stroke-cloudy-blue > div > i > svg > g > path{
    fill: var(--dodger-blue);
}

button.ant-btn.stroke-cloudy-blue:hover > div > i > svg > g > path{
    fill: var(--dark-mint);
}
button.ant-btn.stroke-cloudy-blue:focus > div > i > svg > g > path{
    fill: var(--dark-mint);
}
button.ant-btn.stroke-cloudy-blue:active > div > i > svg > g > path{
    fill: var(--dark-mint);
}
button.ant-btn.stroke-cloudy-blue:hover > div > i > div >  svg > g > path{
    fill: var(--dark-mint);
}
button.ant-btn.stroke-cloudy-blue:active > div > i > div >  svg > g > path{
    fill: var(--dark-mint);
}
button.ant-btn.stroke-cloudy-blue:focus > div > i > div >  svg > g > path{
    fill: var(--dark-mint);
}

.ant-btn.icon{
    height: 25px;
    width: 25px;
    padding: 0;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 2px;
    background-color: var(--white);
    border: solid 1px var(--cloudy-blue);
}
.ant-btn.icon:hover{
    color: var(--azure);
    border: solid 1px var(--azure);
}

.ant-btn.icon:focus{
    color: var(--azure);
    border: solid 1px var(--azure);
}
button.ant-btn.icon:hover > div > i > svg > g > path{
    fill: var(--azure);
}


.ant-btn.icon-only{
    height: 25px;
    width: 25px;
    padding: 0;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 2px;
    background-color: transparent;
    border: none;
    box-shadow: none;
}
.ant-btn.icon-only:hover{
    color: var(--dark-mint);
    background-color: transparent;
    border: none;
}
.ant-btn.icon-only:focus{
    background-color: transparent;
    border: none;
}
button.ant-btn.icon-only:hover > div > i > svg > g > path{
    fill: var(--dark-mint);
}

.eina-semibold-white-15{
    font-family: SFProDisplay-Medium;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
}
.ant-btn.dark-mint{
    width: 100%;
    height: 36px;
    border-radius: 5px;
    background-color: var(--dark-mint);
    border: solid 1px var(--dark-mint);
    color: var(--white);
}
.ant-btn.dark-mint:hover, .ant-btn.dark-mint:focus, .ant-btn.dark-mint:active{
    color: var(--white);
    background-color: var(--dark-seafoam-green);
}

.ant-btn.dark-sky-blue{
    width: 100%;
    height: 36px;
    border-radius: 5px;
    background-color: var(--dark-sky-blue);
    border: solid 1px var(--dark-sky-blue);
    color: var(--white);
}
.ant-btn.dark-sky-blue:hover, .ant-btn.dark-sky-blue:focus, .ant-btn.dark-sky-blue:active{
    color: var(--white);
    background-color: var(--bright-sky-blue);
    border: solid 1px var(--bright-sky-blue);
}


.eina-semibold-cool-grey-15{
    font-family: SFProDisplay-Semibold;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--cool-grey)
}
.eina-semibold-cool-grey-14{
    font-family: SFProDisplay-Semibold;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--cool-grey)
}

.eina-semibold-grapefruit-15{
    font-family: SFProDisplay-Semibold;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--grapefruit)
}

.ant-btn.stroke-salmon{
    width: 100%;
    height: 36px;
    border-radius: 5px;
    background-color: var(--white);
    border: solid 1px var(--salmon);
    color: var(--grapefruit)

}
.ant-btn.stroke-salmon:hover{
    background-color: var(--salmon);
    color: var(--white);
    border: solid 1px var(--salmon);
}

.ant-btn.eina-semibold-dark-seafoam-green-15{
    font-family: SFProDisplay-Semibold;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dark-seafoam-green);
}
.eina-semibold-dark-seafoam-green-10{
    font-family: SFProDisplay-Semibold;
    font-size: 10px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dark-seafoam-green);
}

.eina-regular-slate-grey-14{
    font-family: SFProDisplay-Regular;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--slate-grey);
}

.ant-btn.link{
    border-color: transparent;
    background-color: transparent;
    box-shadow: none;
    height: 30px;
    padding: 0;
}

.ant-btn.link:hover, .ant-btn.link:active, .ant-btn.link:focus{
    border-color: transparent;
    background-color: transparent;
    box-shadow: none;
    height: 30px;
    padding: 0;

}

button.ant-btn.fill-blue > div > i > div > svg > g > path{
    fill: var(--dodger-blue);
}

.ant-btn.link:hover > div > i > svg > g > path{
    fill: var(--dark-mint);
}
.ant-btn.link:focus > div > i > svg > g > path{
    fill: var(--dark-mint);
}
.ant-btn.link:active > div > i > svg > g > path{
    fill: var(--dark-mint);
}

.eina-semibold-dark-mint-14{
    font-family: SFProDisplay-Semibold;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dark-mint);
}

.ant-btn.stroke-dark-mint{
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: solid 2px var(--dark-mint);
    background-color: var(--white);
    color: var(--dark-mint);
  }
  .ant-btn.stroke-dark-mint:hover, .ant-btn.stroke-dark-mint:focus, .ant-btn.stroke-dark-mint:active{
    border: solid 2px var(--dark-mint);
    /* background-color: var(--dark-sky-blue); */
    color: var(--white);
  }

  .eina-semibold-white-14{
    font-family: SFProDisplay-Semibold;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--white);
}


.eina-semibold-white-14{
    font-family: SFProDisplay-Semibold;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--white);
}
.ant-btn.cool-grey{
    width: 100%;
    height: 36px;
    border-radius: 5px;
    background-color: var(--cool-grey);
    border: solid 1px var(--cool-grey);
}
.ant-btn.cool-grey:hover, .ant-btn.cool-grey:focus, .ant-btn.cool-grey:active{
    color: var(--white);
    background-color: var(--steel-grey);
}

.ant-btn.eina-semibold-15-dark-seafoam-green {
    font-family: SFProDisplay-Semibold;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.47;
    letter-spacing: normal;
    color: var(--dark-seafoam-green);
  }



.ant-btn.text-only{
    padding: 0 !important;
    color: var(--dodger-blue);
    background-color: transparent;
    border: none;
    box-shadow: none;
}
.ant-btn.text-only:hover{
    color: var(--water-blue);
    background-color: transparent;
    border: none;
}
.ant-btn.text-only:focus{
    border: 0 !important;
    box-shadow: none !important;
    outline: none !important;
    animation-duration: 0s !important;
}
.ant-btn.text-only:after{
    border: 0 !important;
    box-shadow: none !important;
    outline: none !important;
    animation-duration: 0s !important;
}


.ant-btn.eina-semibold{
    font-family: SFProDisplay-Semibold;
}
.ant-btn.eina{
    font-family: Eina-Regular;
}
.ant-btn.roboto{
    font-family: Roboto-Regular;
}
.ant-btn.roboto-medium{
    font-family: Roboto-Medium;
}

.ant-btn.medium{
    font-family: SFProDisplay-Medium;
    line-height: 1;
}
.ant-btn.regular{
    font-family: SFProDisplay-Regular;
}

.ant-btn.s-10{
    font-size: 10px;
}
.ant-btn.s-12{
    font-size: 12px;
}
.ant-btn.s-13{
    font-size: 13px;
}
.ant-btn.s-14{
    font-size: 14px;
}
.ant-btn.s-15{
    font-size: 15px;
}
.ant-btn.white{
    color: var(--white);
}

.ant-btn.dark-seafoam-green{
    color: var(--dark-seafoam-green);
}

.ant-btn.water-blue{
    color: var(--water-blue);
}

.ant-btn.dodger-blue-two-shadow{
    width: 100%;
    border-radius: 3.3px;
    box-shadow: 2px 2px 8px 0 rgba(93, 160, 215, 0.28);
    background-color: var(--dodger-blue-two);
    color: var(--white);
}

.ant-btn.dodger-blue-two-shadow:hover{
    width: 100%;
    border-radius: 3.3px;
    box-shadow: 2px 2px 8px 0 rgba(93, 160, 215, 0.28);
    background-color: var(--dodger-blue);
    color: var(--white);
}

.ant-btn.dodger-blue-two-shadow:focus{
    width: 100%;
    border-radius: 3.3px;
    box-shadow: 2px 2px 8px 0 rgba(93, 160, 215, 0.28);
    background-color: var(--dodger-blue);
    color: var(--white);
}

.ant-btn.dodger-blue{
    width: 100%;
    border-radius: 3px;
    background-color: var(--dodger-blue-two);
    color: var(--white);
}

.ant-btn.dodger-blue:hover{
    width: 100%;
    border-radius: 3px;
    background-color: var(--dodger-blue);
    color: var(--white);
}

.ant-btn.dodger-blue:focus{
    width: 100%;
    border-radius: 3px;
    background-color: var(--dodger-blue);
    color: var(--white);
}

.ant-btn.app-select-button {
    border: none;
    border-radius: 0;
    border-bottom: solid 2px transparent;
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    color: var(--white);
}
.ant-btn.app-select-button:hover {
    border: none;
    border-radius: 0;
    border-bottom: solid 2px var(--white);
    background-color: transparent;
    color: var(--white);
}
.ant-btn.app-select-button:active {
    border: none;
    border-radius: 0;
    border-bottom: solid 2px var(--white);
    background-color: transparent;
    animation-duration: 0s !important;
    color: var(--white);
}
.ant-btn.app-select-button:focus {
    border: none;
    border-radius: 0;
    border-bottom: solid 2px var(--white);
    background-color: transparent;
    animation-duration: 0s !important;
    color: var(--white);
}
.ant-btn.app-select-button:focus:after {
    border: none;
    border-radius: 0;
    border-bottom: solid 2px var(--white);
    background-color: transparent;
    animation-duration: 0s !important;
}



.ant-btn.app-select-button-border {
    border-radius: 5px;
    border: solid 2px var(--cloudy-blue);
    background-color: var(--white);
    box-shadow: none;
    padding: 0 16px 0 16px;
    color: var(--white);
}
.ant-btn.app-select-button-border > div > i > svg > g > path{
    fill: var(--cloudy-blue-two);
}
.ant-btn.app-select-button-border > div > i > svg > path{
    fill: var(--cloudy-blue-two);
}
.ant-btn.app-select-button-border:hover > div > i > svg > g > path{
    fill: var(--dark-seafoam-green);
}
.ant-btn.app-select-button-border:hover > div > i > svg > path{
    fill: var(--dark-seafoam-green);
}

button.ant-btn.button-upgrade{
    height: 30px;
    background-color: #17222b;
    border-radius: 3px;
    border: solid 1px var(--cloudy-blue);
    color: var(--white);
    font-family: SFProDisplay-Semibold;
    font-size: 13px;
}

button.ant-btn.button-upgrade:hover{
    background-color: var(--white);
    color: #17222b;
}



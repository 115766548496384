.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child{
    stroke-opacity: 0;
}

.yAxis .recharts-layer.recharts-cartesian-axis-tick text{
    transform: translate(-15px, 0);
}
.xAxis .recharts-layer.recharts-cartesian-axis-tick text {
    transform: translate(0, 15px);
}

.segment-table .ant-table-thead > tr > th{
    background-color: var(--white);
    font-family: SFProDisplay-Regular;
    font-size: 13px;
    color: var(--slate-grey);
    height: 55px;

}
.segment-table .ant-table-tbody > tr > td {
    background-color: var(--white);
    font-family: SFProDisplay-Medium;
    font-size: 13px;
    color: var(--greyish-brown-two);

}

.xAxis .recharts-text.recharts-cartesian-axis-tick-value{
    font-family: SFProDisplay-Regular;
    font-size: 13px;
    color: var(--steel-grey);
}

.yAxis .recharts-text.recharts-cartesian-axis-tick-value{
    font-family: SFProDisplay-Regular;
    font-size: 13px;
    color: var(--slate-grey);
}
.side-bar .ant-menu-item-selected{

}

/*.side-bar{*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: space-between;*/
/*    height: 100%;*/
/*    margin-left: 12px;*/
/*    border-right: 1px solid #e8e8e8;*/
/*}*/

.menu-top-upgrade{
    height:calc(100% - 230px);
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: #f1f1f1 #b3b3b3 #f1f1f1;
    scrollbar-width: thin;
}
.menu-top{
    height:calc(100% - 150px);
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: #f1f1f1 #b3b3b3 #f1f1f1;
    scrollbar-width: thin;
}

.menu-top::-webkit-scrollbar {
    width: 2px;
}
.menu-top::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.menu-top::-webkit-scrollbar-thumb {
    background: #b3b3b3;
}
.menu-top::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.menu-bottom{
    height: 150px;
}
.menu-bottom-upgrade{
    height: 230px;
}
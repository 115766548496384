.customer-list {
    padding: 32px 44px 0px 44px;
}

.customer-details {
    padding: 32px 44px 0px 44px;
}


.card-info .ant-skeleton-title{
    margin-top: 4px;
    width: 90%;
}

.card-info-medium .ant-skeleton-title{
    margin-top: 8px;
    width: 90%;
}

.customer-details .ant-table-header-column .ant-table-column-title {
    color: var(--dark-grey-blue-two);
    font-family: SFProDisplay-Semibold;
    font-size: 15px;
}

.customer-list .ant-table-header-column .ant-table-column-title {
    color: var(--slate-grey);
    font-family: SFProDisplay-Medium;
    font-size: 15px;
}

.customer-details .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td{
    padding-top: 20px ;
    padding-bottom: 20px;
}

.customer-details .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td:first-child{
    padding-top: 2px ;
    padding-bottom: 2px;
}

.customer-details tr > td > div{
    height: 36px;
}

.customer-details tr > td > div.ant-row{
    padding-top: 1px;
}

.customer-details .table-list .border{
    height: 74px;
}

.empty-transaction-customer-details{
    min-height: 300px;
    padding: 60px;
    text-align: center;
    width: 100%;
    background: var(--white);
}

/*@import '../node_modules/antd/dist/antd.css';*/
@import "./css/colors.css";
@import "./css/fonts.css";
@import "./css/tags.css";
@import "./css/buttons.css";
@import "./css/button_updated.css";
@import "./css/charts.css";
@import "./css/menu.css";


.antd {
  display: flex;
  align-items: center;
}
.ant-btn{
  height: 42px;
}

.App {
  text-align: center;
}

.antd-logo {
  height: 15vmin;
}
.antd-text {
  height: 7vmin;
  margin-left: 10px;
}
.antd-desc {
  width: 550px;
  font-size: 20px;
  line-height: 40px;
  margin: 30px 0;
}
.ant-input-search-button{
  font-family: SFProDisplay-Semibold;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--white);
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #314659;
}
.ant-layout-header{
  background:  var(--almost-black);

}



.ant-form-item.no-bottom-margin{
  margin-bottom: 10px;
  border-color: #101010;
}

.ant-page-header-heading-extra{
  right: 0px !important;
}

.ant-page-header{
  padding-left: 0px !important;
  padding-right: 0px !important;
  font-family: "Eina 04 W03 Regular";
}

.ant-menu-inline{
  border-right: 0px !important;
}


tspan{
  margin: 20px;
 padding: 20px;
}



.ant-input-search > input{
  font-family: SFProDisplay-Regular;
  font-size: 15px;
  height: 42px;
  font-weight: normal;
  font-style: normal;
  color: var(--cool-grey);

}

.ant-modal-title{
  font-family: SFProDisplay-Semibold;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black);

}

.ant-divider-vertical{
  height: 16px;
}

.product-header{
  width: 100%;
  border-radius: 5px 5px 0 0;
  padding: 14px 14px 12px 18px;
  border: solid 1px var(--pale-grey-two);
  background-color: var(--white);
}

.ant-calendar-picker-input.ant-input{
  height: 36px;
}

.ant-pagination-item{
  font-family: SFProDisplay-Semibold;
}

/*div.ant-tooltip-inner {*/
/*  color: var(--white);*/
/*  font-size: 11px;*/
/*  font-family: Roboto-Regular;*/
/*  background-color: var(--water-blue);;*/
/*}*/


.product-group div.ant-popover-inner-content{
  background-color: var(--water-blue);
  border-radius: 5px;
}
.product-group div.ant-popover-arrow{
  border-right-color:  var(--water-blue) !important;
  border-bottom-color: var(--water-blue) !important;
  border-top-color: var(--water-blue) !important;
  border-left-color: var(--water-blue) !important;
}

.app-select .ant-dropdown-menu{
  margin-top: 4px;
  width: 155px;
  border-radius: 3px;
  box-shadow: 0 2px 16px 0 rgba(33, 43, 54, 0.08), 0 0 0 1px rgba(6, 44, 82, 0.1);
  background-color: var(--white);
}

.settings-form .ant-form-extra {
  font-size: 12px;
  font-family: SFProDisplay-Semibold;

  color: var(--light-grey-blue);
}

.settings-form .ant-form-item-label > label {
  color: var(--greyish-brown);
}

.ant-select-dropdown-menu-item-selected{
  color: #238ee5;
  background: #e8f4ff !important;
}

.app-select .ant-select .ant-select-enabledant-select-dropdown-menu-item-active{
  color: #238ee5;
  background-color: var(--pale-grey) !important;
}

.ant-dropdown-menu-item-active{

  background-color: #e8f4ff !important;
}

.popover-filter .ant-select-selection--single{
    height: 36px;
    padding: 2px;
}
.popover-filter .ant-select-arrow{
  margin-top: -10px;
}
.popover-filter .ant-input{
  height: 36px;
}

.ant-input:hover{
  border-color: var(--dark-sky-blue);
}

.app-select-dashboard .ant-select-selection{
  background: transparent;
  border: none;
}
.app-select-dashboard .app-name{
  color: var(--white);
}

.app-select-dashboard .ant-select-arrow > i > svg{
  fill: var(--white);
}


.ant-layout-header{
  background-color: #17222b;
}

.app-icon-transaction-list{
  margin-top: 1px;
  border-radius: 4px;
}

.price-history-modal{
  background-color: var(--white);
  padding: 20px;
  border-radius: 5px;
}
.filter-tag{
    padding-left: 12px;
    padding-right: 2px;
    height: 42px;
    border-radius: 5px;
    background-color: var(--white);
    border: solid 1px var(--dark-sky-blue);
    font-family: SFProDisplay-Regular;
    font-size: 14px;
    margin-right: 8px;
}

button.ant-btn:hover i > svg > g{
    stroke: var(--dark-sky-blue);
}
.table-item-left-top{
    border: 1px solid var(--pale-grey-two);
    background-color: var(--white);
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 24px;
    width: 200px;
}

.table-item-left{

    border: 1px solid var(--pale-grey-two);
    border-top: none;
    background-color: var(--white);
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 24px;
    width: 200px;
}

.table-item-right{
    border: 1px solid var(--pale-grey-two);
    border-left: none;
    background-color: var(--white);
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 24px;
}

.table-details .ant-table-tbody > tr > td{
    padding-bottom: 22px;
    padding-top: 22px;
}

.table-details .ant-skeleton-title{
    margin: 0 !important;
}

.table-details .ant-skeleton-paragraph{
    margin: 0 !important;
}
.upgrade-modal-view{
    z-index: 100;
    position: absolute;
    bottom: 0;
    right: 10px;
    overflow: hidden;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
}

.upgrade-modal{
    width: 328px;
    height: 374px;
    border-radius: 8px;
    box-shadow: -1px 2px 20px 0 rgba(90, 104, 114, 0.24);
    background-color: var(--white);
}

.upgrade-modal-top {
    top: 0;
    position: absolute;
    width: 328px;
    height: 119px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    padding: 22px;
}

.upgrade-modal-title{
    margin: 18px 16px 14px 22px;
}

.eina-regular-12-slate-grey {
    font-family: Eina-Regular;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--slate-grey);
}

.eina-semi-bold-12-slate-grey {
    font-family: SFProDisplay-Semibold;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--slate-grey)
}

.eina-semi-bold-12-dark-min {
    font-family: SFProDisplay-Semibold;
    color: var(--dark-mint);
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
}

.upgrade-modal-features{
    margin-left: 24px;
    margin-bottom: 6px;
}
.upgrade-modal-buttons{
    margin-left: 24px;
    margin-top: 24px;
}

.eina-semi-bold-12-cool-grey {
    font-family: SFProDisplay-Semibold;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--cool-grey);
}
.upgrade-modal-button{
    width: 160px;
    height: 30px;
    padding-left: 10px;
    background-color: var(--almost-black);
    border-radius: 3px 3px 0px 0px;
}

.dot{
    margin-top: 1px;
    width: 8px;
    height: 8px;
    box-shadow: 0 0 4px 0 rgba(117, 234, 176, 0.42);
    background-color: var(--dark-mint);
    border-radius: 4px;
}
.arrow-up{
    padding-top: 2px;
    width: 8px;
    height: 4px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid var(--cool-grey);
}
.analytics-table-header{
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: 0 1px 0 0 var(--dark-5);
    border: solid 1px var(--pale-grey-two);
    background-color: var(--white);
    padding-top: 20px;
    padding-bottom: 16px;
    padding-left: 20px;
}
.analytics-table-data{
    box-shadow: 0 1px 0 0 var(--dark-5);
    border: solid 1px var(--pale-grey-two);
    border-top: none;
    background-color: var(--white);
    padding-top: 20px;
    padding-bottom: 16px;
    padding-left: 20px;
}
.analytics-table-data-last{
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0 1px 0 0 var(--dark-5);
    border: solid 1px var(--pale-grey-two);
    border-top: none;
    background-color: var(--white);
    padding-top: 20px;
    padding-bottom: 24px;
    padding-left: 20px;
}

.legend-tealish{
    margin-right: 8px;
    width: 15px;
    height: 15px;
    border-radius: 4px;
    background-color: var(--tealish);
}

.legend-apple-green {
    margin-right: 8px;
    width: 15px;
    height: 15px;
    border-radius: 4px;
    background-color: var(--apple-green);
}

.legend-tomato-two{
    width: 15px;
    height: 15px;
    border-radius: 4px;
    background-color: #e33636;
}

.metrics-container{
    padding: 32px 44px 0px 44px;
}
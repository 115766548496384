.panel-chart-summery{
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 var(--dark-5);
    border: solid 1px #dfe6ea;
    background-color: var(--white);
    padding: 25px;
    width: 1025px;
    margin: auto;
}
.dashboard{
    height: 276px;
    background-image: linear-gradient(101deg, #208be3 0%, var(--dodger-blue) 100%);
}

/*.dashboard .ant-calendar-picker{*/
/*    width: 250px !important;*/
/*}*/

.info-action{
    padding: 6px 6px 6px 14px;
    background: var(--white);
    border: 1px solid var(--white);
    border-radius: 5px;
}
.billing-upgrade{
    margin-left: 20px;
    padding: 4px 12px 4px 12px;
    width: 140px;
    height: 40px;
    border-radius: 5px;
    border: solid 1px #75c1ff;
    background-color: var(--dark-sky-blue);
}

.billing-upgrade > i > svg > path{
    fill: #75c1ff
}

.footer-transaction-list > i > svg > path{
    fill: var(--dark-sky-blue);
}

.auto-renew{
    text-align: center;
    border-radius: 4px;
    box-shadow: 0 1px 0 0 var(--dark-5);
    border: solid 1px var(--pale-grey-two);
    background-color: var(--white);
    height: 352px;
}

.dashboard .table-list .ant-table-footer{
    height: 56px;
}

.dashboard .ant-table-thead > tr > th {
    height: 54px;
}

.dashboard .ant-table-placeholder{
    height: 242px;
}

.dashboard-empty-transaction{
    height: 352px;
    padding: 60px;
    text-align: center;
    width: 100%;
    background: var(--white);
}

.extra-feature-title{
    font-family: SFProDisplay-Bold;
    color: var(--white);
    font-size: 16px;
    line-height: 1.3;
    width: 184px;
}
.extra-feature-subtext-regular{
    font-family: SFProDisplay-Regular;
    font-size: 12px;
    line-height: 1.33;
    color: var(--slate-grey);
}
.extra-feature-subtext-bold{
    font-family: SFProDisplay-Bold;
    font-size: 12px;
    line-height: 1.33;
    color: var(--slate-grey);
}
.extra-feature-subtext-bold-dark-mint{
    font-family: SFProDisplay-Bold;
    font-size: 12px;
    line-height: 1.33;
    color: var(--dark-mint);
}

.extra-feature-cancel{
    font-family: SFProDisplay-Semibold;
    font-size: 12px;
    color: var(--dark-grey-blue);
}

.dashboard .ant-skeleton-content .ant-skeleton-title {
    width: 100% !important;
    height: 185px;
    border-radius: 5px;
    margin-top: 0;
    margin-bottom: 0;
}

.dashboard .ant-skeleton-content .ant-skeleton-paragraph{
    margin-bottom: 0 !important;
    margin-top: 0;
}

button.ant-btn.set-up-app{
    border-radius: 5px;
    background-color: var(--dark-sky-blue);
    font-family: SFProDisplay-Semibold;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--white);
}

.onboard-modal-description{
    font-family: SFProDisplay-Regular;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: normal;
    color: var(--battleship-grey-three);
}

.blur-view-overlay-dashboard{
    position: absolute;
    width: 100%;
    z-index: 10;
    text-align: center;
    top: 6px;
}

.blur-view-overlay-dashboard-background{
    background: var(--cloudy-blue);
    opacity: 0.5;
    width: calc(100% - 120px);
    margin-left: 60px;
    margin-right: 60px;
    height: 30px;
    position: absolute;
    border-radius: 5px;
}

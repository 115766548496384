.radio-environment{
    background: var(--white);
    border-radius: 5px;
    border: 1px solid var(--cloudy-blue);
    height: 36px;
    padding-left: 8px;
}

.radio-environment label:first-child{
    padding: 7px;
    border-right: 1px solid var(--cloudy-blue);
}
.radio-environment label span{
    padding-left: 4px;
    padding-bottom: 2px;
}

.radio-environment label{
    font-size: 14px;
    color: var(--slate-grey);
    font-family: SFProDisplay-Medium;
}
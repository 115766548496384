.sign-up {
    text-align: center;
    margin-top: 156px;
    width: 482px;
    border-radius: 8px;
    box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
    border: solid 1px #e5eef5;
    background-image: linear-gradient(to bottom, var(--white), #fbfffd);
    margin-bottom: 128px;
}


.sign-up .ant-input{
    width: 360px;
    height: 42px;
}

#login_email, #login_password, #sign-up_name, #sign-up_email, #sign-up_password, #sign-up_organization_name,
#forgotpass_email, #reset-password_password, #reset-password_passwordConfirm,
#join-up_invitation_user_name, #join-up_email, #join-up_invitation_user_password,
#join-up_organization_name, #social-sign-up_organization_name   {
    height: 42px;
    border: solid 1px #a8c6df !important;
    color: #3f536e;
}

.ant-btn {
    font-size: 15px;
}


.login-container{
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-basis: 482px;
    width: 482px;
    margin-top: 80px;
    padding-bottom: 46px;
    background: var(--white);
    box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
    border: solid 1px var(--pale-grey-three);
    background-color: var(--white);
    border-radius: 4px;
    padding-top: 54px;
}

.login-container .ant-divider-horizontal{
    background-color: #f0f2f4;

}

.top-bar-link{
    font-family: SFProDisplay-Medium;
    font-size: 16px;
    color: var(--charcoal-grey);
    margin-left: 10px;
    margin-right: 10px;
}

.top-bar-link:hover{
    color: var(--deep-sky-blue);
}
.top-bar-link .anticon{
    margin-left: 4px;
}
.top-bar-link .anticon > svg{
    width: 12px;
    fill: var(--deep-sky-blue);
}

.top-bar-menu-item{
    padding: 12px;
    border-radius: 4px;
    background-color: var(--white);
}

.top-bar-menu-item:hover{
    background-color: #f0f8ff;
}
.ant-dropdown-menu-item > a{
    background-color: var(--white);
}

.reset-email-container {
    margin-top: 80px;
    padding: 26px 75px 50px 75px;
    border-radius: 8px;
    box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
    border: solid 1px var(--pale-grey-three);
    background-color: var(--white);
}
.reset-password-container {
    margin-top: 80px;
    width: 480px;
    height: 408px;
    padding: 50px 0px 45px 0px;
    border-radius: 8px;
    box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
    border: solid 1px var(--pale-grey-three);
    background-color: var(--white);
}
.verify-email-container{
    width: 480px;
    padding: 26px 0 50px 0;
    margin-top: 80px;
    border-radius: 8px;
    box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
    border: solid 1px var(--pale-grey-three);
    background-color: var(--white);
}

.button-start-your-journey{
    width: 360px;
    height: 42px;
    border-radius: 4px;
    background-color: #007fff;
    cursor: pointer;
}
.login-container .ant-form-item-label > label{
    font-family: SFProDisplay-Regular !important;
    font-size: 13px !important;
    color: var(--cool-grey) !important;
}

.google-sign-in-button{
    width: 360px;
    height: 42px;
    box-shadow: none !important;
    border: solid 1px var(--dark-sky-blue) !important;
    border-radius: 5px !important;
    margin-bottom: 12px;

}

.google-sign-in-button:hover{
  color: var(--dark-sky-blue) !important;
}

.google-sign-in-button > div{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 6px;
    margin-left: auto !important;

    margin-right: auto !important;

}

.google-sign-in-button > span{
    color: var(--white);
    background: var(--dark-sky-blue);
    width: 100%;
    border-bottom-right-radius: 4px !important;
    border-top-right-radius: 4px !important;
}


.on-boarding{
    padding: 30px 56px 20px 44px;
}

.on-boarding .ant-tabs .ant-tabs-left-content{
    padding-left: 0px;
}

.on-boarding-left-menu{
    position: absolute;
}

.details .ant-menu-item{
    height: 58px;
    border:  solid 1px var(--pale-grey-two);
    background-color: var(--white);
    margin-top: 0px;
    margin-bottom: 0px !important;
    font-family: SFProDisplay-Semibold;
    font-size: 14px;
    color: var(--slate-grey);
    padding-top: 8px;
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box{
    padding: 25px 25px 0;
}

.details .ant-menu-item:hover{
    height: 58px;
    border:  solid 1px var(--pale-grey-two);
    background-color: var(--pale-grey);
    margin-top: 0px;
    margin-bottom: 0px !important;
    font-family: SFProDisplay-Semibold;
    font-size: 14px;
    color: #238ee5;
    padding-top: 8px;
}
.details .ant-menu-item-selected::after{
    border-right: none;
}
.details .ant-menu-item:not(:first-child){
    border-top:  none;
}
.details .ant-menu-item:first-child{
    border-radius:  4px 4px 0 0;
}
.details .ant-menu-item:last-child{
    border-radius: 0 0 4px 4px;
}
.details .ant-menu-item-selected{
    color: var(--dark-sky-blue);
    background: #e8f4ff !important;
}

.on-board-connect-sdk{
    max-width: 530px;
    margin-top: 20px;
    border:  solid 1px var(--pale-grey-two);
    background-color: var(--white);
    padding: 22px;
    margin-bottom: 24px;
    border-radius: 5px;
}

.on-board-connected{
    max-width: 530px;
    margin-top: 20px;
    border: solid 1px var(--light-blue-grey-two);
    background-color: var(--white);
    padding: 38px;
    margin-bottom: 24px;
    border-radius: 5px;
}

.on-board-connect-sdk .key{
    background-color: var(--pale-grey-three);
    padding-left: 14px;
    padding-right: 14px;
    height: 46px;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 16px;
}
.step-configure-product{
    padding: 20px;
    border-radius: 5px;
    border: solid 1px var(--pale-grey-three);
    background-color: var(--white);
}


.on-boarding .ant-form-extra {
    font-family: SFProDisplay-Medium;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--cool-grey);
}

.on-boarding .ant-form-item-label > label{
    font-family: SFProDisplay-Medium;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.46;
    letter-spacing: normal;
    color: var(--greyish-brown);
}

.on-boarding .ant-input{
    font-family: SFProDisplay-Regular;
    font-size: 15px;
    border-radius: 4px;
    border: solid 1px var(--cloudy-blue);
    background-color: var(--white);
    height: 42px;
}

.on-boarding .ant-input::placeholder,.apps .ant-select-selection__placeholder{
    font-family: SFProDisplay-Regular;
    font-size: 15px;
    color: var(--cool-grey) !important;
}

.product-table-header{
    background: var(--white);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 40px;
    padding-left: 16px;
    border: solid 1px var(--pale-grey-three);;
}
.product-table-row{
    background: var(--white);
    padding-left: 16px;
    padding-right: 16px;
    border: solid 1px var(--pale-grey-three);
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.product-table-row-purchased {
    border-radius: 5px;
    border: solid 1px var(--pale-grey-three);
    background-color: #fcfeff;
    padding: 16px;
}

.onboard-product-instruction{
    border-radius: 6px;
    background-color: #eff8ff;
    padding: 12px;
}

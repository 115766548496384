
:root {
    --apple-green: #81c926;
    --dark-mint-12: rgba(70, 196, 134, 0.12);
    --azure: #0093ee;
    --light-yellow-green-16: rgba(216, 255, 134, 0.16);
    --ice-blue: #e7f4ff;
    --off-white: #fff8e1;
    --pale-lavender: #f6e7ff;
    --very-light-pink: #fff3ec;
    --dark-seafoam-green: #3bb278;
    --faded-orange: #e99442;
    --dodger-blue: #2f9cf5;
    --pink-purple: #de2ff5;
    --ice-blue-two: #e3f8ff;
    --bright-sky-blue: #40a9ff;
    --dark-sky-blue: #1890ff;
    --orange-red: #ff5a1e;
    --greyish-brown: #404040;
    --tomato: #e83330;
    --steel-grey: #7b8488;
    --dark: #212b36;
    --battleship-grey: #697986;
    --dark-mint-15: rgba(70, 196, 134, 0.15);
    --white: #ffffff;
    --light-urple: #ab7df6;
    --cool-grey: #a3afb8;
    --greyblue: #8dabc4;
    --slate-grey: #5a6872;
    --dark-grey-blue: #a3afb8;
    --dark-grey-blue-two: #2c405a;
    --tealish: #26c1c9;
    --cloudy-blue: #d4dde5;
    --bluey-grey: #9dafba;
    --dark-mint: #46c486;
    --bluey-grey-two: #919eab;
    --dusk: #3f536e;
    --black: #262626;
    --almost-black: #0f291c;
    --battleship-grey-two: #637381;
    --pine: #387758;
    --silver: #e1e5e7;
    --pale-grey: #f6f8fa;
    --light-blue-grey: #a8c6df;
    --pale-grey-two: #e5eef5;
    --dark-5: rgba(22, 29, 37, 0.05);
    --pale-grey-three: #eff2f4;
    --black-two: #131313;
    --cerulean: #007ace;
    --cloudy-blue-two: #d0d9df;
    --salmon: #ff7e7e;
    --grapefruit: #ff5e5e;
    --water-blue: #1a82d7;
    --pale-red: #dd4747;
    --cool-grey-two: #b2b7bb;
    --cool-grey-three: #b1b7bb;
    --dark-mint-two: #4fcb91;
    --pale-grey-four: #f2f5f7;
    --light-blue-grey-two: #c7e7d7;
    --dusk-two: #525675;
    --battleship-grey-three: #66737e;
    --greyish-brown-two: #535353;
    --tomato-two: #e33636;
    --cool-grey-four: #979fa5;
    --dodger-blue-two: #4aa2f9;
    --steel: #7e8995;
    --very-light-blue: #d7edff;
    --cool-grey-five: #8b93a0;
    --black-three: #101010;
    --dusk-three: #3f5072;
    --light-grey-blue: #a3afb8;
    --dark-two: #26303e;
    --dark-three: #2c3647;
    --ice-blue-three: #f3f8ff;
    --algae-green: #1fbb64;
    --reddish: #c83330;
    --pale-grey-five: #f4f6f8;
    --dusty-orange: #f37024;
    --pale-blue: #dfe6ea;
    --ocean: #00848e;
    --cool-green: #2dc56c;
    --deep-sky-blue: #007fff;
    --charcoal-grey: #323a3e;
}
@font-face {
  font-family: 'Eina-Regular';
  src: local('Eina-Regular'),

  url('./fonts/eina/Eina04-Regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/eina/Eina04-Regular.woff') format('woff'), /* Modern Browsers */
  url("./fonts/eina/Eina04-Regular.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}
@font-face {font-family: 'Eina-SemiBold';
  src: local('Eina-SemiBold'),
  url('./fonts/eina/Eina04-SemiBold.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/eina/Eina04-SemiBold.woff') format('woff'), /* Modern Browsers */
  url('./fonts/eina/Eina04-SemiBold.ttf') format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}

@font-face {font-family: 'Eina-Bold';
  src: local('Eina-Bold'),
  url('./fonts/eina/Eina04-Bold.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/eina/Eina04-Bold.woff') format('woff'), /* Modern Browsers */
  url("./fonts/eina/Eina04-Bold.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}

@font-face {font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'),
  url('./fonts/roboto/Roboto-Medium.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/roboto/Roboto-Medium.woff') format('woff'), /* Modern Browsers */
  url("./fonts/roboto/Roboto-Medium.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}

@font-face {font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'),
  url('./fonts/roboto/Roboto-Regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/roboto/Roboto-Regular.woff') format('woff'), /* Modern Browsers */
  url("./fonts/roboto/Roboto-Regular.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}


@font-face {font-family: 'SFProDisplay-Regular';
  src: local('SFProDisplay-Regular'),
  url("./fonts/sfprodisplay/SFProDisplay-Regular.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}
@font-face {font-family: 'SFProDisplay-Medium';
  src: local('SFProDisplay-Medium'),
  url("./fonts/sfprodisplay/SFProDisplay-Medium.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}
@font-face {font-family: 'SFProDisplay-Bold';
  src: local('SFProDisplay-Bold'),
  url("./fonts/sfprodisplay/SFProDisplay-Bold.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}
@font-face {font-family: 'SFProDisplay-Semibold';
  src: local('SFProDisplay-Semibold'),
  url("./fonts/sfprodisplay/SFProDisplay-Semibold.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}

body {
  margin: 0;
  font-family:  'SFProDisplay-Regular', "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

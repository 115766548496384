.ant-form-extra {
    font-family: SFProDisplay-Semibold;
    font-size: 10px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--cool-grey);
}

.ant-form-item-label > label{
    font-family: SFProDisplay-Medium;
    font-size: 13px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.46;
    letter-spacing: normal;
    color: var(--greyish-brown) !important;
}

.ant-input{
    font-family: SFProDisplay-Medium;
    font-size: 15px;
    border-radius: 4px;
    border: solid 1px var(--cloudy-blue);
    background-color: var(--white);
    height: 42px;
}

.ant-input::placeholder, .ant-select-selection__placeholder{
    font-family: Eina-Regular;
    font-size: 15px;
    color: var(--cool-grey) !important;
}

.Eina-Semibold-13-water-blue {
    font-family: SFProDisplay-Regular;
    font-size: 13px;

    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--water-blue);
  }


.medium-slate-grey{
    font-family: SFProDisplay-Regular;
    font-size: 12px;
    color: var(--slate-grey);
}
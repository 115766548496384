

.legend-square-dodger-blue{
    width: 15px;
    height: 15px;
    border-radius: 4px;
    background-color: var(--dodger-blue);
}

.legend-square-very-light-blue{
    width: 15px;
    height: 15px;
    border-radius: 4px;
    background-color: var(--very-light-blue);
}

.ant-progress-circle-trail{
    stroke: var(--very-light-blue) !important;
}

.create-app-form-wrapper {
    margin-top: 30px;
    margin-left: 44px;
}

.account-details{
    padding: 30px 56px 20px 44px;
}

.account-details .ant-tabs .ant-tabs-left-content{
    padding-left: 0px;
}

.account-details-left-menu{
    position: absolute;
}

.details .ant-menu-item{
    height: 58px;
    border:  solid 1px var(--pale-grey-two);
    background-color: var(--white);
    margin-top: 0px;
    margin-bottom: 0px !important;
    font-family: SFProDisplay-Semibold;
    font-size: 14px;
    color: var(--slate-grey);
    padding-top: 8px;
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box{
    padding: 25px 25px 0;
}

.details .ant-menu-item:hover{
    height: 58px;
    border:  solid 1px var(--pale-grey-two);
    background-color: var(--pale-grey);
    margin-top: 0px;
    margin-bottom: 0px !important;
    font-family: SFProDisplay-Semibold;
    font-size: 14px;
    color: #238ee5;
    padding-top: 8px;
}
.details .ant-menu-item-selected::after{
    border-right: none;
}
.details .ant-menu-item:not(:first-child){
    border-top:  none;
}
.details .ant-menu-item:first-child{
    border-radius:  4px 4px 0 0;
}
.details .ant-menu-item:last-child{
    border-radius: 0 0 4px 4px;
}
.details .ant-menu-item-selected{
    color: var(--dark-sky-blue);
    background: #e8f4ff !important;
}

.account{
    max-width: 536px;
}



.account-update-form{
    border:  solid 1px var(--pale-grey-two);
    background-color: var(--white);
    padding: 28px 24px 12px 24px;
    margin-bottom: 24px;
    border-radius: 5px;
}

.account .ant-input{
    height: 42px;
}

.cost-explorer-container{
    border:  solid 1px var(--pale-grey-two);
    background-color: var(--white);
    padding: 30px 28px 28px 28px;
    margin-bottom: 24px;
    border-radius: 5px;
}

.plan{
    text-align: center;
    width: 160px;
    border-radius: 5px;
    border: solid 1px var(--cool-grey);
    background-color: var(--white);
    padding: 24px;
    margin-bottom: 12px;
}

.plan:hover{
    border: solid 1px var(--dark-mint);
}

.eina-semibold-dark-mint-12 {
    font-family: SFProDisplay-Semibold;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-decoration: underline;
    color: var(--dark-mint);
}

.eina-regular-cool-grey-12 {
    font-family: Eina-Regular;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--cool-grey);
}
.eina-regular-dark-mint-12 {
    font-family: Eina-Regular;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--dark-mint);
}

.subscription-plans .recommended{
    background-color: var(--dodger-blue);
    height: 24px;
    margin: 0 12px 0 12px;
    border-radius: 4px 4px 0px 0px;
}
.subscription-plans .not-recommended{
    height: 16px;
    margin: 8px 12px 0 12px;
    border-radius: 4px 4px 0px 0px;
    border: solid 1px var(--pale-grey-two);
    border-bottom: none;
}

.subscription-plans .recommended-plan{
    height: 100%;
    margin: 0 12px;
    border-radius: 0 0 4px 4px;
    border: solid 1px var(--pale-grey-two);
    background-color: var(--white);
    border-top: none;
}
.subscription-plans .subscription-plan{
    height: 100%;
    margin: 0 12px;
    border-radius: 0 0 4px 4px;
    border: solid 1px var(--pale-grey-two);
    background-color: var(--white);
    border-top: none;
}

.subscription-plans .current-plan{
    width: 208px;
    height: 26px;
    border-radius: 3.3px;
    border: solid 0.7px var(--water-blue);
    background-color: var(--white);
}


.subscription-plans .oval {
    margin-left: 42px;
    margin-right: 12px;
    border-radius: 6px;
    width: 6px;
    height: 6px;
    background-color: var(--cool-grey);
}

.ant-radio-inner{
    height: 14px;
    width: 14px;
}

.ant-radio-inner:after{
    height: 8px;
    width: 8px;
    top: 2px;
    left: 2px;
}

.add-payment input {
    height: 34px;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--dark-sky-blue);
}

.pm-table .ant-table-thead {
    display: none;
}

.model-invoice .ant-modal-content{
    width: 800px;
}
.ant-modal.model-invoice{
    margin-right: 280px;
}

.model-upgrade-product .ant-modal-content{
    width: 550px;
}
.ant-modal.model-upgrade-product{
    margin-right: 26px;
}

.model-upgrade-product .ant-modal-header {
    padding: 38px 0px 28px 22px;
}

.invoice-col-3{
    text-align: right;
}

.invoice-content{
    padding-left: 12px;
    padding-right: 12px;
}
.invoice-item{
    padding-top: 32px ;
    /*padding-bottom: 32px;*/
    border-top: solid 2px var(--pale-grey-three);
    /*border-bottom: solid 2px var(--pale-grey-three);*/
    margin-top: 6px;
    margin-bottom: 6px;
}

.invoice-item-overage{
    padding-top: 8px ;
    padding-bottom: 32px;
    /*border-top: solid 2px var(--pale-grey-three);*/
    border-bottom: solid 2px var(--pale-grey-three);
    margin-top: 6px;
    margin-bottom: 6px;
}
.invoice-item-divider {
    /*padding-top: 32px ;*/
    padding-bottom: 24px;
    /*border-top: solid 2px var(--pale-grey-three);*/
    border-bottom: solid 2px var(--pale-grey-three);
}

.account .ant-skeleton-avatar{
    width: 60px;
    height: 60px;
}
.account .ant-skeleton-paragraph > li{
    width: 100% !important;
}

.account .ant-input[disabled]{
    background-color: var(--white);
    height: 36px;
    border-radius: 5px 0 0 5px;
    font-family: SFProDisplay-Regular;
    cursor: pointer ;
}

button.ant-btn.image-upload {
    height: 36px;
    width: 78px;
    border-radius: 0 5px 5px 0;

    border: solid 1px #d0d9df;
    border-left: none;
    background-color: var(--pale-blue);
    color: var(--slate-grey);
    font-family: SFProDisplay-Semibold;
}

.limit-overage-container{
    border-radius: 6px;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05);
    background-color: #f0fafa;
    padding: 18px 36px 30px 12px;
}

.limit-overage-container-content {
    width: calc(100% - 40px);
}

.limit-overage-applicable-rectangle {
    width: 30px;
    height: 20px;
    border-radius: 4px;
    border: solid 1px var(--dark-sky-blue);
    background-color: #eff8ff;
}

.invoice-content .ant-tag{
    height: 24px;
    border-radius: 12px;
    padding: 16px;
    font-family: SFProDisplay-Semibold !important;
}



.cost-explorer .ant-table-tbody > tr > td {
    padding: 0;
    border: none;
}

.cost-explorer .ant-table-thead > tr > th{
    background-color: var(--white);
    border: 1px solid var(--pale-grey-three) ;
    border-left: none;
}

.cost-explorer .ant-table-thead > tr > th:first-child{
    border-top-left-radius: 4px;
    border: 1px solid var(--pale-grey-three) ;
}
.cost-explorer .ant-table-thead > tr > th:last-child{
    border-top-right-radius: 4px;
}

.mtr-current-plan{
    padding:  18px;
    border: solid 1px var(--dark-sky-blue);
    background-color: #eff8ff;
}

.mtr-current-plan-analyze{
    padding:  18px;
    border: solid 1px var(--dark-sky-blue);
    background-color: #eff8ff;
    border-left: none;
}


.mtr-current-plan-business{
    padding:  18px;
    border: solid 1px var(--dark-sky-blue);
    background-color: #eff8ff;
    border-right: none;
}

.cost-explorer .ant-table-tbody > tr > td:first-child > .mtr-current-plan:first-child{
    border-left: solid 1px var(--dark-sky-blue);;
}
.cost-explorer-default{
    padding: 18px;
    background-color: var(--white);
    border: 1px solid var(--pale-grey-three) ;
    border-top: none;
    border-left: none;
}

.cost-explorer .ant-table-tbody > tr > td:first-child > .cost-explorer-default:first-child {
    border-left: 1px solid var(--pale-grey-three) ;
}

.cost-explorer .ant-table-tbody > tr:last-child > td:first-child > .cost-explorer-default:first-child {
    border-bottom-left-radius: 4px;
}

td.mtr-overage-cell {
    border: solid 1px var(--dark-sky-blue) !important;
    border-bottom-right-radius: 4px;
    padding: 15px !important;
    background-color: #eff8ff !important;
}

td.mtr-no-overage-cell {
    border: solid 1px var(--pale-grey-three) !important;
    border-left: none !important;
    border-top: none !important;
    border-bottom-right-radius: 4px;
    padding: 15px !important;
    /*background-color: #eff8ff !important;*/
}

.payment-cards{
    width: 100%;
    padding: 28px 24px 12px 24px;
    min-height: 72px;
    border-radius: 5px;
    border: solid 1px var(--pale-grey-three);
    background-color: var(--white);
}

.payment-method-row{
    padding-top: 12px;
    padding-bottom: 12px;
    border-top: 1px solid var(--pale-grey-three);
}

.payment-method-row-modal{
    padding-top: 12px;
    padding-bottom: 12px;
    border-top: 1px solid var(--pale-grey-three);
    border-bottom: 1px solid var(--pale-grey-three);
}

.add-payment input{
    height: 38px;
}

.StripeElement {
    box-sizing: border-box;
    height: 38px;
    padding: 10px 12px;
    border: 1px solid #d0d9df;
    border-radius: 5px;
    background-color: white;
    box-shadow: none;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}

.StripeElement--focus {
    border: solid 1px var(--dark-sky-blue);
}

.StripeElement--hover {
    border: solid 1px var(--dark-sky-blue);
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.subscription-plans{
    border:  solid 1px var(--pale-grey-two);
    background-color: var(--white);
    padding: 30px 28px 28px 28px;
    margin-bottom: 24px;
    border-radius: 5px;
}

.payment-method-row{
    min-height: 57px;
}

.billing .anticon > svg{
    width: 12px;
}







.ant-table-wrapper.table-list{
    margin-bottom: 32px;
}
.ant-table-body{
    font-family: SFProDisplay-Semibold;
}
.table-list .ant-table-body {
    margin: 0px !important;
}

.ant-table-thead > tr > th{
    border-bottom: 2px solid var(--pale-grey) !important;
    height: 50px;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.46;
    letter-spacing: normal;
}


.ant-table-tbody > tr > td{
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.46;
    letter-spacing: normal;
    color: var(--greyish-brown);
}

.table-list .ant-table-thead {
    background-color: rgb(255, 255, 255);
}

.table-list .ant-table-tbody {
    background-color: var(--pale-grey-five);
}
.table-list .ant-table-tbody > tr{
    height: 44px;
    background: var(--white);
}
.ant-table-tbody > tr > td {
    /*border-bottom: 2px solid var(--pale-grey) !important;*/
}
.table-list .ant-table-tbody > tr > td:first-child{
    padding: 0px !important;
}
.table-list .ant-table-tbody > tr > td:last-child{
    padding: 0px !important;
    height: 48px;
}

.table-list .ant-table-row:hover{
    border-left: 9px solid #041407;
}

.table-list .border {
    border-left: 2px solid var(--dark-sky-blue);
    height: 47px;
    width: 1px;
}

.table-list .color-code {
    border: 2px solid #c8274a;
}

.table-list .ant-pagination-item-active{
    background: var(--dark-sky-blue);
    border-color: var(--azure);
    height: 24px;
    border-radius: 5px;
    color: var(--white);
}
.table-list .ant-pagination-item > a:hover{
    color: var(--dark-sky-blue);
}

.table-list .ant-pagination-item-active:hover a{
    color: var(--white);
}
.table-list .ant-pagination-item-active a{
    color: var(--white);
}
.table-list .ant-table-footer{
    padding: 8px;
    background-color: rgb(255, 255, 255) !important;
    border-top: 2px solid var(--pale-grey) !important;
    /*border-top: none !important;*/
    height: 50px;
}

.table-list .ant-tag{
    height: 24px;
    border-radius: 12px;
    padding: 16px;
    font-family: SFProDisplay-Semibold !important;

}

.footer-row{
    height: 100%;
    font-family: SFProDisplay-Medium;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dark-grey-blue);
}

.table-details .ant-table-thead {
    background-color: rgb(255, 255, 255);
}

.table-details .ant-table-tbody {
    background-color: var(--white);
}

.pagination-navigation-button{
    margin-left:12px;
    margin-right: 12px;
    height: 24px;
    border-radius: 2px;
    width: 24px;
}

.pagination-navigation-button > i {
    margin-left: -5px;
}

td i svg {
    width: 30px;
    height: 18px;
}



.table-list .ant-skeleton-title{
    margin: 0 16px 0 16px !important;
    width: 80% !important;
}
.table-list .ant-skeleton-paragraph{
    margin: 0 !important;
}
.ant-spin-dot{
    top: 65% !important;
}

.row-count > div > label{
    margin-left: 16px;
    margin-right: 16px;
    font-family: SFProDisplay-Medium;
    font-size: 12px;
    color: var(--dark-grey-blue);
}
.row-count svg > g > path {
    fill: var(--dark-grey-blue);
}

.row-count > div > i {
    padding-top: 2px;
}

.ant-empty-normal{
    visibility: hidden;
}

.dashboard .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot{
    margin-top: -80px;
}
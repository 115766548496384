
.submenu.ant-menu-item-selected  {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    font-family: SFProDisplay-Semibold;
    letter-spacing: normal;
    color: var(--dark-sky-blue);

  }

  .ant-menu-item-selected{
    background-color: rgba(47, 156, 245, 0.1) !important;
  }

  .menu.ant-menu-item-selected > a{
    color: var(--dark-sky-blue) !important;
  }
  .ant-menu-submenu-open.ant-menu-submenu-selected > .ant-menu-submenu-title >span > span{
    color: var(--dark-sky-blue) !important;
  }



  .ant-menu-item .anticon{
    width: 16px;
  }
  .ant-menu-submenu .anticon{
    width: 16px;
  }

  .ant-menu-item-selected .anticon > svg > g > path{
    fill: var(--dark-sky-blue)
  }

  .ant-menu-submenu-selected .ant-menu-submenu-title .anticon > svg > g > path{
    fill: var(--dark-sky-blue)
  }

  .menu.ant-menu-item {
    font-size: 14px;

    font-style: normal;
    font-stretch: normal;
    font-family: SFProDisplay-Semibold;
    letter-spacing: normal;
    color: var(--dark);
  }

  .menu.ant-menu-item a {
    font-size: 14px;

    font-style: normal;
    font-stretch: normal;
    font-family: SFProDisplay-Semibold;
    letter-spacing: normal;
    color: var(--dark);
  }
  .menu.ant-menu-item i {
    font-size: 14px;

    font-style: normal;
    font-stretch: normal;
    font-family: SFProDisplay-Semibold;
    letter-spacing: normal;
    color: var(--dark);
  }

  .ant-menu-submenu-title{
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    font-family: SFProDisplay-Semibold;
    letter-spacing: normal;
    color: var(--dark);
  }
  .ant-menu-submenu-title span {
    font-size: 14px;

    font-family: SFProDisplay-Semibold;
    color: var(--dark);
  }

  .submenu{
    width: calc(100% - 36px) !important;
    padding-left: 12px !important;
    margin-left: 36px !important;
  }

  .submenu > a{
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    font-family: SFProDisplay-Semibold;
    letter-spacing: normal;
    color: var(--battleship-grey) !important;
  }

  .submenu.ant-menu-item-selected > a{
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    font-family: SFProDisplay-Semibold;
    letter-spacing: normal;
    color: var(--dark-sky-blue) !important;
  }

  .footer.ant-menu-item{
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: var(--dark);
  }
  .footer.ant-menu-item-selected{
    color: var(--pine) !important;
  }

  .ant-menu-submenu-arrow{
    left: 0;
    margin-left: 8px;
  }
  .ant-layout-sider{
    max-width: 232px !important;
    min-width: 232px !important;
    box-shadow: inset -1px 0 0 0 var(--pale-grey);
  }

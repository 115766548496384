.chart-details {
    width: 100%;
    margin-top: 32px;
    border-radius: 4px;
    border: solid 2px var(--pale-grey);
    background-color: var(--white);
}

.chart-summery .recharts-responsive-container{
    position: absolute;
}
.recharts-tooltip-wrapper.recharts-tooltip-wrapper-left.recharts-tooltip-wrapper-top{

}

.blur-view{
    z-index: 1;
    width: 100%;
    filter: blur(3px);
    -webkit-filter: blur(3px);
    backdrop-filter: blur(3px);
}

.blur-view-off{
    z-index: 1;
    width: 100%;
}

.chart-view{
    display: flex;
    flex-flow: row nowrap;
}

.blur-view-chart{
    margin-left: -100%;
    width: 100%;
    height: 400px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
}

.blur-view-overlay{
    margin-left: -100%;
    width: 100%;
    height: 400px;
    z-index: 100;
    text-align: center;
}

.chart-summery .ant-card-body {
    width: 308px;
    height: 185px;
    padding: 20px;
    zoom: 1;
    border-radius: 10px;
  /*box-shadow: 0 1px 3px 0 rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05);*/
    border-radius: 10px;
    border: solid 1px var(--cloudy-blue);
  transition: all 0.3s;
  transition-property: all;
  transition-duration: 0.5s, 0s;
  transition-timing-function: ease, ease;
  transition-delay: 0s, 0s;
}


.chart-summery .ant-card-body:hover {
    background-color: var(--white) !important;
    transition: all 0.3s;
    transition-property: all;
    transition-duration: 0.5s, 0s;
    transition-timing-function: ease, ease;
    transition-delay: 0s, 0s;
}


/*.apexcharts-bar-area{*/
/*    background-color: #0f291c;*/
/*}*/

.apexcharts-grid > .apexcharts-gridlines-vertical > .apexcharts-gridline{
    stroke:var(--pale-grey-two);
    stroke-width:1;
}

.apexcharts-grid > .apexcharts-gridlines-horizontal > .apexcharts-gridline{
    stroke-dasharray: none;
    stroke-width:1;
    stroke:var(--pale-grey-two);
}


/*.apexcharts-series > .apexcharts-bar-area {*/
/*    fill-opacity:1;*/
/*}*/

.chart-dropdown{
    cursor: pointer;
}





.transaction-list{
    padding: 32px 44px 0px 44px;
}

.transaction-list .ant-table-header-column .ant-table-column-title {
    color: var(--dark-grey-blue-two);
    font-family: SFProDisplay-Semibold;
    font-size: 14px;

}

.empty-transaction{
    min-height: 460px;
    padding: 60px;
    text-align: center;
    width: 100%;
    background: var(--white);
}

.radio-environment{
    background: var(--white);
    border-radius: 5px;
    border: 1px solid var(--cloudy-blue);
    height: 36px;
    padding-left: 8px;
}

.radio-environment label:first-child{
    padding: 7px;
    border-right: 1px solid var(--cloudy-blue);
}
.radio-environment label span{
    padding-left: 4px;
    padding-bottom: 2px;
}

.radio-environment label{
    font-size: 14px;
    color: var(--slate-grey);
    font-family: SFProDisplay-Medium;
}
.app-select .ant-select-selection--single{
    padding: 3px;
    height: 36px;
}

.app-select label{
    color: var(--slate-grey);
    font-family: SFProDisplay-Semibold;
    font-size: 14px;
    margin-left: 4px;
}

.app-name{
    color: var(--slate-grey);
    font-family: SFProDisplay-Regular;
    font-size: 14px;
    margin-left: 4px;
}
.app-icon{
    margin-bottom: 3px;
    border-radius: 4px;
}
.app-icon-default{
    margin-bottom: 3px;
}
.app-image{
    padding-bottom: 15px;
}

.application-not-selected-container-content{
    width: calc(100% - 50px);
}
.application-not-selected-container{
    border-radius: 6px;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05);
    background-color: #e8f4ff;
    padding: 12px 8px 12px 16px;
    margin-bottom: 12px;
}

.transaction-details{
    padding: 30px 44px 60px 44px ;
}

.transaction-list .ant-radio-inner::after{
    background-color: var(--dark-sky-blue);
}
.transaction-list .ant-radio-input:focus + .ant-radio-inner, .transaction-list .ant-radio-checked::after{
    border-color: var(--dark-sky-blue);
}